/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS340M
 * 화면 설명: 청약서 재발행
 */

<template> 
  <!-- AppBar BackArrow 버튼/ 타이틀 / 검색버튼 컨테이너 -->
  <!-- root ur-page-container -->
  <ur-page-container class="msp" title="청약서 재발행" :show-title="true" type="subpage" ref="page" action-type="search" :topButton="true"
    @action-search-click="fn_HeaderSerchHander" 
    @on-scroll-bottom="fn_ScrollBottomHandler"
    @on-header-left-click="fn_HeaderBackBtnHandler">

    <!-- 조회건수 출력 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="count">{{rsltCnt}}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- / 조회건수 출력 -->
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
          <ur-box-container alignV="start" componentid="" direction="column" class="">      
            <mo-list :list-data="rsltItemList">
              <template #list-item="{item, index}">      
                <mo-list-item class=""> 
                  <msp-expand titleFirst btnAreaFirst  :expanded="index === 0" class="mo-list-expand w100" btn-area-class="fexTy3 firstdiv-full mb0"  > 
                    <template #title>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title pt6">
                          <span class="name txtSkip fs19rem fwb">{{item.prdtNmLabel}}</span>
                        </div>
                        <div class="mt6 mb10">
                          <span class="fs14rem mr6 crTy-bk7">주피보험자</span> <span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 ls--1">{{item.mnisdNm}}</span>
                          <em class="em_normal">|</em>
                          <span class="fs14rem mr8 crTy-bk7">등록일</span><span class="crTy-bk1 fs16rem ls--1">{{item.inptYmdLabel}}</span>
                        </div>
                      </div>
                    </template>
                    <template #btn>
                      <mo-button class="link-arrow down mb34"></mo-button>
                    </template>
                    <template #content>
                      <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                        <ur-box-container class="con--padding ">
                          <div class="contents-row">
                              <span class="dsInline crTy-bk7 fs14rem txtSkip--psjjh mr20 min60">계약자</span><span class="fs16rem">{{item.contrNm}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min60">영수증번호</span><span class="fs16rem">{{item.planId}}</span>
                          </div>
                        </ur-box-container>
                      </div>
                    </template>
                  </msp-expand>
                </mo-list-item>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
                  <div class="ns-btn-relative-area mt0">
                    <div class="ns-btn-relative-s">
                      <div class="relative-div mb5">
                        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_SubmitClickHandler(item)">재발행</mo-button>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- NoData 영역 ur-box-containe  -->        
      <PSNodataMesgBoxForList v-else ref="nodataMesgBox"/>        
      <!-- / NoData 영역 ur-box-containe -->
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import MSPPS301P from '@/ui/ps/MSPPS301P'
import Screen from '~systems/mixin/screen'
export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS340M', 
  screenId: 'MSPPS340M',
  mixins: [Screen],

  components: {    
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList, //데이터없음 메세지 출력 박스
    'MSPPS301P' : MSPPS301P, //검색 키워드 입력 팝업
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      stndKey: '', // 페이징 Next Key
      isNodata: false, // 데이터 없음 Flag            
      slctItamData : null, // 선택된 item Row Object Data  
      rsltCnt : 0 ,  // 조회결과 누적 데이터 길이
      rsltItemList : [], // 조회결과 리스트 데이터
      searchPopup : null, //검색팝업
      rpblPopup : null //재발행 팝업
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/


  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.fn_Reset()            
    this.fn_ServiceData('S')
  },//mounted
  
  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated


  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
   /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    }, //fn_DevicBackBtnHandler
    
    
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$router.push({ name: 'MSPBC002M' })
    },// fn_HeaderBackBtnHandler


    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화
    ******************************************************************************/
    fn_Reset: function () {
      this.stndKey = ''        
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
    },

  /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {
       this.searchPopup = this.$bottomModal.open(MSPPS301P, {
        properties: {
          pTitle : '청약서 상세조건',
          pParntSrnId: this.$options.screenId, // 부모 화면 ID
          pSearchKeyword: this.searchKeyword, //Default set Keyword Value
          pInptScVal: 'V', //입력구분값'V' 영수증번호 , 'P' 발행번호
        },

        listeners: {
          //대상팝업 onPopupReset $emit 이벤트명 backkey reset or reset
          onPopupReset : () => {
            this.fn_Reset()            
            this.fn_ServiceData('S')
            this.$bottomModal.close(this.searchPopup) // 모달 닫기    
          },
          // 대상팝업 onPopupSearch $emit 이벤트명 
          onPopupSearch: (rtnData) => {
            console.log(rtnData)
            this.searchKeyword = rtnData.searchKeyword // 입력키워드
            this.fn_Reset()            
            this.fn_ServiceData('S')
            this.$bottomModal.close(this.searchPopup) // 모달 닫기                        
          }          
        }
      })
    },// fn_HeaderSerchHander


    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_ServiceData('N')
    },// fn_ScrollBottomHandler


    /******************************************************************************
     * Function명 : fn_SubmitClickHandler
     * 설명 : 재발행 출력 버튼 클릭
     ******************************************************************************/
    fn_SubmitClickHandler: function (item) {
      this.slctItamData = item

      if (this.slctItamData && this.slctItamData.planId) {
        this.fn_BottomConfirm('영수증 번호 : [' + this.slctItamData.planId + ']<br/> 청약서를 재발행 하시겠습니까?')
        
      } else {
        this.getStore('confirm').dispatch('ADD', '청약서 재발행 대상을 선택 하세요')
      } // end else if
    },

    /******************************************************************************
     * Function명 : fn_BottomConfirm
     * 설명       : Confirm 팝업 호출
     ******************************************************************************/
   fn_BottomConfirm (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '',
         content: contents,
         title_pos_btn: "예",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_ServiceData('T')
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
   },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData(serviceName) {
     
     // 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
     if (serviceName === 'N' && this.stndKey === '') return

      let pParams = {}
      switch (serviceName) {
        case 'S': // 청약서 재발행 조회(신규)
          window.vue.getStore('progress').dispatch('SKEL_LIST')
        case 'N': // 청약서 재발행 조회(추가)
          
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS28S4'))
          pParams.pageRowCnt = '20'
          pParams.stndKeyList = [{'stndKeyId': 'next_key', 'stndKeyVal': this.stndKey}]
          pParams.data = {}
          pParams.data.clctCnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트번호
          pParams.data.planId = this.searchKeyword
          break
        case 'T': // 청약서 재발행 처리
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS28T4'))
          pParams.data = {}
          pParams.data.planId = this.slctItamData['planId'] // 영수증 번호
          pParams.data.clctCnsltId = PSServiceManager.getUserInfo('userId') // 모집컨설턴트번호
          pParams.data.smtotPrm = {} // 합계보험료
          pParams.data.smtotPrm.amount = this.slctItamData['smtotPrm'] // 합계보험료
          break
        default:
          break
      } // end switch
            
      pParams.srnId = this.$options.screenId // 호출 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
      
    },

    /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data
      let stndKeyList = event.trnstComm.stndKeyList
      switch (serviceName) {
        case 'S':
          window.vue.getStore('progress').dispatch('PART')
        case 'N':
          let pIVuchPblInqr1 = lv_data.pivuchPblInqrVO
          let t_rowIndex = this.rsltItemList.length
          // 재발행 Data 저장
          if (pIVuchPblInqr1 && pIVuchPblInqr1.length > 0) {

            pIVuchPblInqr1.forEach(element => {
              const listObj = {}
              listObj.idx = t_rowIndex++
              listObj.planId = element.planId // 발행번호
              listObj.prcd = element.prcd // 상품코드
              listObj.prdtNm = element.prdtNm // 상품명
              listObj.prdtNmLabel = element.prdtNm.replace('삼성생명', '').replace('삼성', '')
              listObj.contrNm = element.contrNm // 계약자명
              listObj.mnisdNm = element.mnisdNm // 주피보험자명
              listObj.inptYmd = element.inptYmd // 등록일
              listObj.inptYmdLabel = PSDateUtil.fn_DateFormat(element.inptYmd, 'yyyy-MM-dd', PSDateUtil.LV_FORMAT_YYYYMMDD) // 등록일
              listObj.smtotPrm = element.smtotPrm.amount // 합계보험료
              listObj.pblScCd = element.pblScCd // 발행구분코드
              listObj.vo = element
              this.rsltItemList.push(listObj)
            })

            // 페이징 처리
            if (stndKeyList && stndKeyList.length > 0) {
              this.stndKey = stndKeyList[0].stndKeyVal ? stndKeyList[0].stndKeyVal : ''
            } // end if

          } else {
            if (serviceName === 'S') this.fn_TableStat() // 조회결과 없음 처리
          } // end if
          
          this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
          this.isNodata = (this.rsltCnt <= 0)? true : false
          
          // if(this.rsltCnt > 0 && serviceName === 'S'){
          //   this.$nextTick(()=>{
          //     PSCommUtil.fn_MoListItemExpandIdx(this.$refs.moList,0,true)
          //   })
          // }
          break
        case 'T': // 청약서 재발행 처리
        
          if (lv_data) {
            this.getStore('confirm').dispatch('ADD', lv_data.rsltCntnt)
            //this.$refs.bttmRpblBtnSheet.close() // 처리후 하단 재발행 영역 close
          } // end if
          break

        default:
          break
      } // end switch
      
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler(event, serviceName) {
      switch (serviceName) {
        case 'S':
          this.fn_TableStat()
          if (event && event.msgCd !== 'EFWS034') {
            this.getStore('confirm').dispatch('ADD', event.msgDesc)
          } else {
            // 다른 시스템 호출 간 오류가 발생하였습니다.
            this.getStore('confirm').dispatch('ADD', '데이터 없는 경우이니\n확인후 다시 시도하세요')
          } // end if          
          break
        case 'T':
          this.getStore('toast').dispatch('ADD', '대상시스템과 통신 중 대상시스템에서 에러가 발생했습니다.')
          break
        default:
          break
      } // end switch
      
      this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0 
    },
    /************************************************************************************************
     * Function명  : fn_TableStat
     * 설명        : 데이터 없는 페이지 설정
     * 
     ************************************************************************************************/
    fn_TableStat() {
      this.isNodata = true      
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
    },

  _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>